import * as Sentry from '@sentry/remix';

/**
 * Event name format: "<noun> <past-tense action>"
 */
type EventName = 'Background Check Ordered' | 'Membership Purchased';

export function trackEvent(
  eventName: EventName,
  payload?: Record<string, any>
) {
  try {
    // TODO: Look into Sentry breadcrumbs
    window.heap.track(eventName, payload);
  } catch (err) {
    Sentry.captureException(err);
  }
}

export function identifyUser({
  email,
  id,
  firstName,
  lastName
}: {
  email: string;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
}) {
  Sentry.setUser({ id, email });

  window.heap.identify(id);
  window.heap.addUserProperties({ id, email });

  window.HappyFoxChat?.setVisitorInfo({
    name: firstName && lastName ? `${firstName} ${lastName}` : undefined,
    email
  });
}

export function trackPageView() {
  window.HappyFoxChat?.pageLoaded();
}
